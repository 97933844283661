import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import http from "../../utils/http";
import csvDownload from "../../utils/jsonToCsvDownload";

const ExcelMarksUpload = () => {
    const { id } = useParams();
    const [file, setFile] = useState(null);
    const [items, setItems] = React.useState([]);
    const [uploadStatus, setUploadStatus] = useState("");
    const [errors, setErrors] = React.useState({});

    const getCandidates = React.useCallback(() => {
        http.get(`/api/v1/assessment-candidate/${id}`).then((res) => {
            setItems(res.data)
        });
    }, [id]);

    React.useEffect(() => {
        getCandidates()
    }, [getCandidates]);

    // Handle Excel download
    const handleDownload = async () => {
        try {
            // Create CSV data dynamically
            let data = items.candidates.map((item) => {
                let row = {
                    "Candidate Name": item.name,
                    "Candidate ID": item.candidate_id,
                };
                // if (items.viva) 
                row[`Viva(${items.vivaMarks})`] = "";
                // if (items.demo) 
                row[`Demo(${items.demoMarks})`] = "";
                return row;
            });

            // Trigger CSV download
            csvDownload(data, "Candidates.csv");
        } catch (error) {
            console.error("Error downloading template:", error);
        }
    };

    // Handle file selection
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!file) {
            setUploadStatus("Please select a file before uploading.");
            return;
        }
        setErrors({});
        const formData = new FormData();
        formData.append("file", file);

        try {
            await http.post(`/api/v1/assessment-candidate/${id}/upload-marks`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    // Calculate the percentage of upload completed
                    const percentComplete = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setUploadStatus(`Uploading... ${percentComplete}%`);
                },
            });
            setUploadStatus("File uploaded successfully!");
        } catch (error) {
            console.error("Error uploading file:", error);
            setUploadStatus("Failed to upload file. Please check errors.");
            setErrors(error.response?.data?.errors || {});
        }
    };

    return (
        <div>
            <div className="breadcrum">
                <div className="p-4 margin-b-20 bg-white rounded-lg border border-gray-200 shadow-md">
                    <h2 className="assess-f">Upload Marks With Excel</h2>
                    <nav className="flex" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dash-f">
                                    <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Dashboard
                                </Link>
                            </li>
                            <li aria-current="page">
                                <Link to="/today-assessment" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dash-f">
                                    <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                    Today Assessment
                                </Link>
                            </li>
                            <li aria-current="page">
                                <div className="flex items-center">
                                    <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                    <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dash-f">Upload Marks</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="mb-6">
                    <h3 className="text-md font-semibold mb-2">Step 1: Download Template</h3>
                    <button
                        onClick={handleDownload}
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Download Excel Template
                    </button>
                    <p className="text-sm text-gray-500 mt-2">
                        Download the Excel template to fill in the candidate marks.
                    </p>
                </div>

                <div className="mb-6">
                    <h3 className="text-md font-semibold mb-2">Step 2: Upload Excel File</h3>
                    <input
                        type="file"
                        accept=".xlsx, .csv"
                        onChange={handleFileChange}
                        className="block w-full p-3 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                    />
                    <button
                        onClick={handleUpload}
                        className="bg-green-600 text-white px-4 py-2 mt-4 rounded hover:bg-green-700"
                    >
                        Upload File
                    </button>
                    {uploadStatus && (
                        <p
                            className={`mt-2 text-sm ${uploadStatus.includes("success") ? "text-green-600" : "text-red-600"
                                }`}
                        >
                            {uploadStatus}
                        </p>
                    )}
                    <div>
                        {Object.keys(errors).length > 0 && (
                            <div className="p-5 mt-5 bg-white shadow-md rounded-lg">
                                <table className="min-w-full table-auto">
                                    <thead>
                                        <tr className="border-b ">
                                            <th className="px-4 py-2 text-left">Row Number</th>
                                            <th className="px-4 py-2 text-left">Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(errors).map((row, index) => (
                                            <tr key={index} className="border-b ">
                                                <td className="px-4 py-2">{row}</td>
                                                <td className="px-4 py-2">
                                                    <div dangerouslySetInnerHTML={{ __html: Object.values(errors[row]).join('<br/>') }}></div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

                <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                    <h4 className="text-sm font-semibold mb-2">Instructions:</h4>
                    <ul className="text-sm text-gray-600 list-disc pl-5">
                        <li>Download the template and fill in the candidate marks.</li>
                        <li>Ensure the Excel file format matches the template structure.</li>
                        <li>Upload the updated file using the upload button above.</li>
                        <li>Once uploaded, the marks will be processed and updated.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ExcelMarksUpload;
