// import * as React from 'react';
// import {
//     AgoraVideoPlayer,
//     createMicrophoneAndCameraTracks,
// } from "agora-rtc-react";
// import http from "../../utils/http";

// const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

// const Videos = ({ role, users, tracks }) => {
//     return (
//         <React.Fragment>
//             <div className="assess-de h-2/5">
//                 <p>{role.name === 'candidate' ? 'Candidate' : 'Assessor'}</p>
//                 <div className="can-he" style={{ height: '100%', width: '100%' }}>
//                     <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{ height: 'inherit', width: 'inherit' }} />
//                 </div>
//             </div>
//             <div className="assess-de h-2/5">
//                 <p>{role.name === 'candidate' ? 'Assessor' : 'Candidate'}</p>
//                 <div className="can-he" style={{ height: '100%', width: '100%' }}>
//                     {users.length > 0 &&
//                         [users[0]].map((user) => {
//                             if (user.videoTrack) {
//                                 return <AgoraVideoPlayer videoTrack={user.videoTrack} style={{ height: 'inherit', width: 'inherit' }} key={user.uid} />;
//                             } else return null;
//                         })}
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// const VivaVideoCall = ({ role, channelName, client, config, onStarted , recordingEnable}) => {
//     const [users, setUsers] = React.useState([]);
//     const [start, setStart] = React.useState(false);
//     const { ready, tracks } = useMicrophoneAndCameraTracks();

//     const startRecording = React.useCallback((channel, token) => {
//         http.post(`/api/v1/agora/start-recording`, {
//             uid: 0,
//             token: token,
//             channel: channel
//         }).then((res) => {
//             console.log(res);
//         });
//     }, []);

//     const leaveChannel = React.useCallback(async () => {
//         if (tracks) {
//             await client.leave();
//             client.removeAllListeners();
//             if (tracks.length > 0) {
//                 tracks[0].close();
//             }
//             if (tracks.length > 1) {
//                 tracks[1].close();
//             }
//             setStart(false);
//         }
//     }, [tracks, client]);

//     React.useEffect(() => {
//         // function to initialise the SDK
//         let init = async (name) => {
//             client.on("user-published", async (user, mediaType) => {
//                 await client.subscribe(user, mediaType);
//                 if (mediaType === "video") {
//                     setUsers((prevUsers) => {
//                         return [...prevUsers, user];
//                     });
//                 }
//                 if (mediaType === "audio") {
//                     user.audioTrack?.play();
//                 }
//             });

//             client.on("user-unpublished", (user, type) => {
//                 if (type === "audio") {
//                     user.audioTrack?.stop();
//                 }
//                 if (type === "video") {
//                     setUsers((prevUsers) => {
//                         return prevUsers.filter((User) => User.uid !== user.uid);
//                     });
//                 }
//             });

//             client.on("user-left", (user) => {
//                 setUsers((prevUsers) => {
//                     return prevUsers.filter((User) => User.uid !== user.uid);
//                 });
//             });

//             await client.join(config.appId, name, config.token, null);
//             if (tracks) {
//                 await client.publish([tracks[0], tracks[1]]);
//                 if (role.name === 'assessor' && recordingEnable) {
//                     onStarted();
//                 }
//             };
//             setStart(true);
//         };

//         if (ready && tracks) {
//             init(channelName);
//         }
//     }, [startRecording, role, channelName, client, ready, tracks, config, recordingEnable, onStarted]);

//     React.useEffect(() => {
//         console.info('started');
//         return () => {
//             leaveChannel();
//         }
//     }, [leaveChannel]);

//     return (
//         <div className='col-span-2'>
//             {start && tracks && <Videos role={role} users={users} tracks={tracks} />}
//         </div>
//     );
// };

// export default VivaVideoCall;

import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";
import { io } from "socket.io-client";
import { MdScreenShare } from "react-icons/md";
import { AiOutlineFullscreen } from "react-icons/ai";
import ScreenRecorder from '../../components/ScreenRecording'
import ReactDOM from 'react-dom';

const VivaVideoCall = (props) => {

    const { userId, channelName, onStarted, role, recordingEnable } = props
    const { recorder } = ScreenRecorder()
    const socket = useMemo(() => io(process.env.REACT_APP_API_URL), []);

    const [screenSharing, setScreenSharing] = useState(false);
    // const [roomFull, setRoomFull] = useState(false);
    const [roomTotalUser, setRoomTotalUser] = useState(0);
    const [userDetails, setUserDetails] = useState({});
    const connections = useRef({});
    const cName = useRef({});
    const micInfo = useRef({});
    const videoInfo = useRef({});
    const audioTrackSent = useRef({});
    const videoTrackSent = useRef({});
    let videoAllowed = 1;
    let audioAllowed = 1;

    useEffect(() => {
        if (role.name === 'assessor' && roomTotalUser > 1 && (Object.values(userDetails).includes(channelName) && (recordingEnable && recorder === null))) {
            onStarted();
        }
    }, [onStarted, recordingEnable, recorder, role.name, userDetails, roomTotalUser, channelName])

    //Local Video Ref
    const localVideoRef = useRef(null);

    // Remote Video Containers
    const videoContainer = useRef(null);

    // Set Local Video Stream Information
    const constraints = useMemo(() => ({
        audio: { echoCancellation: true },
        video: {
            width: { min: 400 },
            height: { min: 400 },
        },
    }), []);

    // Define Configuration settings for RTC Connection
    const rtcConfiguration = useMemo(() => ({
        iceServers: [
            {
                urls: ["stun:stun.l.google.com:19302", "stun:global.stun.twilio.com:3478"],
            },
        ],
    }), []);

    // When Socket Connect
    const handleConnect = useCallback(() => {
        socket.emit("join:room", { userId, roomId: channelName });
    }, [socket, channelName, userId]);

    // Send Local Tracks to connection
    const handleSendTracks = useCallback(async () => {
        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                    localVideoRef.current.muted = true;
                }
                stream.getTracks().forEach(track => {
                    for (let key in connections.current) {
                        connections.current[key].addTrack(track, stream);
                        if (track.kind === 'audio') {
                            audioTrackSent[key] = track;
                        } else {
                            videoTrackSent[key] = track;
                        }
                    }
                })

            })
            .catch(err => console.error(err));
    }, [constraints, localVideoRef]);

    //Handle Show User Video In full screen
    function toggleFullScreen(videoElement) {
        if (!document.fullscreenElement) {
            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.mozRequestFullScreen) {
                videoElement.mozRequestFullScreen();
            } else if (videoElement.webkitRequestFullscreen) {
                videoElement.webkitRequestFullscreen();
            } else if (videoElement.msRequestFullscreen) {
                videoElement.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }

    // When Room Connect
    const handleJoinRoom = useCallback(async (connection, connectionNames, micSocket, videoSocket) => {
        try {

            if (connectionNames) {
                cName.current = connectionNames;
            }
            if (micSocket) {
                micInfo.current = micSocket;
            }
            if (videoSocket) {
                videoInfo.current = videoSocket;
            }

            if (connection) {
                connection.forEach((sid) => {
                    connections.current[sid] = new RTCPeerConnection(rtcConfiguration);
                    connections.current[sid].onicecandidate = function (event) {
                        if (event.candidate) {
                            socket.emit("new:icecandidate", event.candidate, sid);
                        }
                    };

                    connections.current[sid].ontrack = (event) => {
                        if (!document.getElementById(sid)) {
                            let vidCont = document.createElement("div");
                            let newVideo = document.createElement('video');
                            let name = document.createElement("div");
                            let btnDiv = document.createElement("div");
                            name.classList.add("font-bold", "py-2", "text-center");
                            if (role.name === 'candidate') {
                                name.innerHTML = `Assessor`;
                            } else {
                                name.innerHTML = `Candidate`;
                            }
                            vidCont.id = sid;
                            newVideo.classList.add('w-full');
                            btnDiv.classList.add('my-2', 'text-center');

                            // Create an element to render the React component
                            const btn = document.createElement('button');
                            btn.classList.add('bg-[#3c4043]', 'rounded-full', 'p-[15px]', 'text-2xl');
                            ReactDOM.render(<AiOutlineFullscreen color="white" />, btn);

                            newVideo.autoplay = true;
                            newVideo.playsinline = true;
                            newVideo.id = `video${sid}`;
                            newVideo.srcObject = event.streams[0];

                            btn.onclick = () => {
                                toggleFullScreen(newVideo);
                            }
                            btnDiv.appendChild(btn)
                            vidCont.appendChild(name);
                            vidCont.appendChild(newVideo);
                            vidCont.appendChild(btnDiv);

                            videoContainer.current.appendChild(vidCont);
                        }
                    };

                    connections.current[sid].onremovetrack = () => {
                        if (document.getElementById(sid)) {
                            document.getElementById(sid).remove();
                        }
                    };

                    connections.current[sid].onnegotiationneeded = async () => {
                        try {
                            const offer = await connections.current[sid].createOffer();
                            await connections.current[sid].setLocalDescription(offer);
                            socket.emit("video:offer", connections.current[sid].localDescription, sid);
                        } catch (error) {
                            reportError(error);
                        }
                    };
                });

                await handleSendTracks();
                console.log("Added all sockets to connection ");
            } else {
                console.log("Waiting for someone to join");
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((localStream) => {
                        if (localVideoRef.current) {
                            localVideoRef.current.srcObject = localStream;
                            localVideoRef.current.muted = true;
                        }
                    })
                    .catch(err => console.error(err));
            }
        } catch (error) {
            reportError(error);
        }
    },
        [cName, rtcConfiguration, socket, handleSendTracks, constraints, localVideoRef, role]
    );

    // Handle Video Offer
    const handleVideoOffer = useCallback(async (offer, sid, cname, micInformation, vidInformation) => {
        try {
            cName.current[sid] = cname;
            micInfo.current[sid] = micInformation;
            videoInfo.current[sid] = vidInformation;
            connections.current[sid] = new RTCPeerConnection(rtcConfiguration);
            connections.current[sid].onicecandidate = function (event) {
                if (event.candidate) {
                    socket.emit("new:icecandidate", event.candidate, sid);
                }
            };

            connections.current[sid].ontrack = (event) => {
                if (!document.getElementById(sid)) {
                    let vidCont = document.createElement("div");
                    let newVideo = document.createElement('video');
                    let name = document.createElement("div");
                    let btnDiv = document.createElement("div");
                    name.classList.add("font-bold", "py-2", "text-center");
                    if (role.name === 'candidate') {
                        name.innerHTML = `Assessor`;
                    } else {
                        name.innerHTML = `Candidate`;
                    }
                    vidCont.id = sid;
                    newVideo.classList.add('w-full');
                    btnDiv.classList.add('my-2', 'text-center');

                    // Create an element to render the React component
                    const btn = document.createElement('button');
                    btn.classList.add('bg-[#3c4043]', 'rounded-full', 'p-[15px]', 'text-2xl');
                    ReactDOM.render(<AiOutlineFullscreen color="white" />, btn);

                    newVideo.autoplay = true;
                    newVideo.playsinline = true;
                    newVideo.id = `video${sid}`;
                    newVideo.srcObject = event.streams[0];

                    btn.onclick = () => {
                        toggleFullScreen(newVideo);
                    }
                    btnDiv.appendChild(btn)
                    vidCont.appendChild(name);
                    vidCont.appendChild(newVideo);
                    vidCont.appendChild(btnDiv);

                    videoContainer.current.appendChild(vidCont);
                }
            };

            connections.current[sid].onremovetrack = () => {
                if (document.getElementById(sid)) {
                    document.getElementById(sid).remove();
                }
            };

            connections.current[sid].onnegotiationneeded = async () => {
                try {
                    const offer = await connections.current[sid].createOffer();
                    await connections.current[sid].setLocalDescription(offer);
                    socket.emit("video:offer", connections.current[sid].localDescription, sid);
                } catch (error) {
                    reportError(error);
                }
            };

            const desc = new RTCSessionDescription(offer);

            connections.current[sid].setRemoteDescription(desc)
                .then(() => { return navigator.mediaDevices.getUserMedia(constraints) })
                .then((localStream) => {
                    localStream.getTracks().forEach(track => {
                        connections.current[sid].addTrack(track, localStream);
                        if (track.kind === 'audio') {
                            audioTrackSent[sid] = track;
                            if (!audioAllowed) {
                                audioTrackSent[sid].enabled = false;
                            }
                        }
                        else {
                            videoTrackSent[sid] = track;
                            if (!videoAllowed) {
                                videoTrackSent[sid].enabled = false
                            }
                        }
                    })
                })
                .then(() => {
                    return connections.current[sid].createAnswer();
                })
                .then(answer => {
                    return connections.current[sid].setLocalDescription(answer);
                })
                .then(() => {
                    socket.emit('video:answer', connections.current[sid].localDescription, sid);
                })
                .catch((err) => console.error(err));
        } catch (error) {
            reportError(error);
        }
    }, [rtcConfiguration, videoContainer, socket, constraints, audioAllowed, videoAllowed, role]);

    // Handle New Ice Candidate
    const handleNewIceCandidate = useCallback(async (candidate, sid) => {
        try {
            const newCandidate = new RTCIceCandidate(candidate);
            await connections.current[sid].addIceCandidate(newCandidate).catch(reportError);
        } catch (error) {
            reportError(error);
        }
    }, []);

    // Handle Video Answer
    const handleVideoAnswer = useCallback(async (answer, sid) => {
        try {
            const ans = new RTCSessionDescription(answer);
            await connections.current[sid].setRemoteDescription(ans);
        } catch (error) {
            reportError(error);
        }
    }, []);

    // Handle Total User Count In Your Room
    const handleUserCount = useCallback((usersCount, users) => {
        setUserDetails(users)
        setRoomTotalUser(usersCount);
    }, []);

    //Remove User Stream When User Disconnect
    const handleDisconnect = useCallback((sid) => {
        if (document.getElementById(sid)) {
            document.getElementById(sid).remove();
        }
        delete connections.current[sid];
    }, [])

    useEffect(() => {
        socket.on("connect", handleConnect);
        socket.on("join:room", handleJoinRoom);
        socket.on("user:count", handleUserCount);
        socket.on("video:offer", handleVideoOffer);
        socket.on("new:icecandidate", handleNewIceCandidate);
        socket.on("video:answer", handleVideoAnswer);
        socket.on("remove:peer", handleDisconnect);
        return () => {
            socket.off("connect", handleConnect);
            socket.off("join:room", handleJoinRoom);
            socket.off("user:count", handleUserCount);
            socket.off("video:offer", handleVideoOffer);
            socket.off("new:icecandidate", handleNewIceCandidate);
            socket.off("video:answer", handleVideoAnswer);
            socket.off("remove:peer", handleDisconnect);
        };
    }, [
        socket,
        handleConnect,
        handleJoinRoom,
        handleUserCount,
        handleVideoOffer,
        handleNewIceCandidate,
        handleVideoAnswer,
        handleDisconnect
    ]);

    // Handle Start Share Screen
    const startScreenShare = () => {
        let screenMediaPromise;

        if (!screenSharing) {
            if (navigator.getDisplayMedia) {
                screenMediaPromise = navigator.getDisplayMedia({ video: true });
            } else if (navigator.mediaDevices.getDisplayMedia) {
                screenMediaPromise = navigator.mediaDevices.getDisplayMedia({ video: true });
            } else {
                screenMediaPromise = navigator.mediaDevices.getUserMedia({
                    video: { mediaSource: "screen" },
                });
            }
        }

        screenMediaPromise.then((stream) => {
            setScreenSharing(true);
            // Replace the camera track with the screen track
            for (let key in connections.current) {
                const sender = connections.current[key].getSenders().find((s) => (s.track ? s.track.kind === "video" : false));
                sender.replaceTrack(stream.getVideoTracks()[0]);
            }

            // Update localVideoRef to the screen sharing stream
            localVideoRef.current.srcObject = stream;

            localVideoRef.current.srcObject.getVideoTracks()[0].onended = function () {
                setScreenSharing(true);
                stopScreenShare();
            };
        })
            .catch((e) => {
                alert("Unable to share screen:" + e.message);
                console.error(e);
            });
    };

    //Handle Stop Screen Sharing
    const stopScreenShare = () => {
        // Clean up the screen sharing track
        const screenTrack = localVideoRef.current.srcObject.getVideoTracks()[0];
        screenTrack.stop();

        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
            // Replace the camera track with the screen track
            for (let key in connections.current) {
                const sender = connections.current[key].getSenders().find((s) => (s.track ? s.track.kind === "video" : false));
                sender.replaceTrack(stream.getVideoTracks()[0]);
            }

            // Update localVideoRef to the screen sharing stream
            localVideoRef.current.srcObject = stream;

            setScreenSharing(false);
        })
            .catch((e) => {
                alert("Unable to stop share screen:" + e.message);
                console.error(e);
            });
    };

    //Stop Camera Function
    const stopCamera = () => {
        const localStream = localVideoRef.current?.srcObject;

        if (localStream) {
            localStream.getTracks().forEach((track) => {
                track.stop();
            });

            // Clear the srcObject to stop displaying the camera stream
            localVideoRef.current.srcObject = null;
        }
    }

    //Handle When User Cut Call
    const handleEndCall = useCallback(() => {
        // Assuming connections.current is an object containing RTCPeerConnection instances
        for (let sid in connections.current) {
            connections.current[sid].close();
        }
        stopCamera()
        socket.disconnect()

        if (videoContainer.current) {
            videoContainer.current.remove();
        }
    }, [socket])

    useEffect(() => {
        console.info('started');
        return () => {
            handleEndCall();
        }
    }, [handleEndCall]);

    return (
        <>
            <div id="remoteStream" className="w-full col-span-2" ref={videoContainer}>
                <div className="text-center">
                    <div className='font-bold py-2'>{role.name === 'candidate' ? 'Candidate' : 'Assessor'}</div>
                    <video className="w-full" playsInline muted ref={localVideoRef} autoPlay ></video>
                    <div className='my-2' >
                        {screenSharing ?
                            <button onClick={stopScreenShare} title="Share Screen" className='bg-[#f54b4b] rounded-full p-[15px]' >
                                <span className="text-2xl">
                                    <MdScreenShare color='white' />
                                </span>
                            </button> :
                            <button onClick={startScreenShare} title="Share Screen" className='bg-[#3c4043] rounded-full p-[15px]' >
                                <span className="text-2xl">
                                    <MdScreenShare color='white' />
                                </span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


export default VivaVideoCall


// import * as React from 'react';
// import io from 'socket.io-client';
// import Peer from 'simple-peer';
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { MdScreenShare } from "react-icons/md";
// import { AiOutlineFullscreen } from "react-icons/ai";
// import ScreenRecorder from '../../components/ScreenRecording'

// const VivaVideoCall = (props) => {

//     const { userId, channelName, onStarted, role, recordingEnable } = props

//     const { recorder } = ScreenRecorder()

//     const [stream, setStream] = React.useState();
//     const [users, setUsers] = React.useState({});
//     const [caller, setCaller] = React.useState("");
//     const [yourID, setYourID] = React.useState("");
//     const [callerSignal, setCallerSignal] = React.useState();
//     const [receivingCall, setReceivingCall] = React.useState(false);
//     const [callingFriend, setCallingFriend] = React.useState(false);
//     const [callAccepted, setCallAccepted] = React.useState(false);
//     const [callRejected, setCallRejected] = React.useState(false);
//     const [candidateOffline, setCandidateOffline] = React.useState(false)
//     const [isConnected, setIsConnected] = React.useState(false);

//     const userVideo = React.useRef();
//     const partnerVideo = React.useRef();
//     const socket = React.useRef();
//     const connectionRef = React.useRef();

//     React.useEffect(() => {
//         if (!(channelName in users)) {
//             setCallAccepted(false)
//         }
//     }, [channelName, users])

//     const endCall = React.useCallback(() => {
//         connectionRef.current.destroy();
//         setReceivingCall(false)
//         setCallAccepted(false)
//         setCallingFriend(false)
//         setCallRejected(false)
//     }, [])

//     const callPeer = React.useCallback((id) => {
//         if (id !== '' && users[id] && id !== yourID) {

//             if (connectionRef.current) {
//                 connectionRef.current.destroy();
//                 socket.current.off('callAccepted');
//             }

//             setCaller(id)
//             setCallingFriend(true)

//             const peer = new Peer({
//                 initiator: true,
//                 trickle: false,
//                 config: {
//                     iceServers: [
//                         { url: 'stun:stun.l.google.com:19302' },
//                         {
//                             url: 'turn:numb.viagenie.ca',
//                             credential: 'muazkh',
//                             username: 'webrtc@live.com'
//                         }
//                     ]
//                 },
//                 stream: stream,
//             });

//             connectionRef.current = peer;

//             peer.on("signal", data => {
//                 socket.current.emit("callUser", { userToCall: id, signalData: data, from: yourID })
//             })

//             peer.on("stream", stream => {
//                 if (partnerVideo.current) {
//                     partnerVideo.current.srcObject = stream;
//                 }
//             });

//             peer.on('error', (err) => {
//                 console.log(err)
//                 endCall()
//             })

//             socket.current.on("callAccepted", signal => {
//                 setIsConnected(true);
//                 setCallAccepted(true);
//                 peer.signal(signal);
//             })

//             return "success"

//         } else {
//             setCandidateOffline(true)
//         }
//     }, [endCall, stream, users, yourID])

//     const acceptCall = React.useCallback(() => {
//         setCallAccepted(true);
//         setIsConnected(true);

//         const peer = new Peer({
//             initiator: false,
//             trickle: false,
//             stream: stream,
//         });

//         connectionRef.current = peer

//         peer.on("signal", data => {
//             socket.current.emit("acceptCall", { signal: data, to: caller })
//         })

//         peer.on("stream", stream => {
//             partnerVideo.current.srcObject = stream;
//         });

//         peer.on('error', (err) => {
//             console.log(err)
//             endCall()
//         })

//         peer.signal(callerSignal);

//         // Check if the peer connection is established
//         if (peer.connected) {
//             console.log("Peer connection established");
//         } else {
//             console.log("Peer connection not yet established");
//         }

//     }, [caller, callerSignal, endCall, stream])

//     function renderLanding() {
//         if (!callRejected && !callAccepted && !callingFriend) {
//             return 'block'
//         }
//         return 'none'
//     }

//     function renderCall() {
//         if (!callRejected && !callAccepted && !callingFriend) {
//             return 'none'
//         }
//         return 'block'
//     }

//     let incomingCall;
//     if (receivingCall && !callAccepted && !callRejected) {
//         incomingCall = (acceptCall())
//     }

//     const startStreaming = React.useCallback(() => {
//         socket.current = io.connect(process.env.REACT_APP_API_URL);

//         socket.current.on('connect', () => {
//             console.log("Connected");
//             setIsConnected(true);
//         });

//         socket.current.on('disconnect', () => {
//             console.log("Disconnected");
//             setIsConnected(false);
//             setCallAccepted(false)
//         });

//         socket.current.on("yourID", (id) => {
//             setYourID(id);
//         })

//         socket.current.on("UserLeft", (id) => {
//             console.log("User Disconnect " + id)
//             setIsConnected(false);
//         })

//         socket.current.on("allUsers", (users) => {
//             setUsers(users);
//         })

//         socket.current.on("UserSignal", (data) => {
//             setReceivingCall(true);
//             setCaller(data.from);
//             setCallerSignal(data.signal);
//         })

//         return () => {
//             socket.current.disconnect();
//             console.log('Socket disconnected');
//             if (connectionRef.current) {
//                 connectionRef.current.destroy();
//                 setIsConnected(false);
//                 socket.current.off('callAccepted');
//             }
//         };
//     }, [])

//     const getUserMedia = React.useCallback(async () => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
//             setStream(stream);
//             if (stream) {
//                 userVideo.current.srcObject = stream;
//                 if (userId) {
//                     socket.current.emit("UsersID", { id: userId })
//                 }
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     }, [userId])

//     const shareScreen = () => {
//         navigator.mediaDevices.getDisplayMedia({
//             audio: true,
//             video: {
//                 mediaSource: 'screen',
//                 cursor: 'always',
//                 displaySurface: 'monitor',
//                 frameRate: { ideal: 60, max: 120 }
//             }
//         })
//             .then(screenStream => {
//                 connectionRef.current.replaceTrack(stream.getVideoTracks()[0], screenStream.getVideoTracks()[0], stream)
//                 userVideo.current.srcObject = screenStream
//                 screenStream.getTracks()[0].onended = () => {
//                     connectionRef.current.replaceTrack(screenStream.getVideoTracks()[0], stream.getVideoTracks()[0], stream)
//                     userVideo.current.srcObject = stream
//                 }
//             })
//     }

//     const callCandidate = React.useCallback(() => {
//         if (channelName in users) {
//             callPeer(channelName)
//             console.log("Calling " + channelName)
//             if (recordingEnable && recorder === null && role.name === 'assessor') {
//                 onStarted()
//             }
//         } else {
//             setIsConnected(false);
//             setCallAccepted(false);
//         }
//     }, [callPeer, channelName, onStarted, recorder, recordingEnable, role.name, users])

//     React.useEffect(() => {
//         if (!callAccepted && !callingFriend && !candidateOffline) {
//             callCandidate();
//         }
//     }, [callAccepted, callCandidate, callingFriend, candidateOffline, role.name])

//     React.useEffect(() => {
//         startStreaming()
//         getUserMedia()
//     }, [getUserMedia, startStreaming])

//     React.useEffect(() => {
//         if (!callAccepted && !isConnected) {
//             if (channelName in users) {
//                 callPeer(channelName)
//                 console.log("Calling Again " + channelName)
//             }
//         }
//     }, [callAccepted, callPeer, channelName, isConnected, users])


//     React.useEffect(() => {
//         return () => {
//             if (stream) {
//                 stream.getTracks().forEach((track) => {
//                     track.stop();
//                     socket.current.disconnect();
//                     setCallingFriend(false)
//                     setCallAccepted(false)
//                     setIsConnected(false)
//                     if (connectionRef.current) {
//                         connectionRef.current.destroy();
//                         socket.current.off('callAccepted');
//                     }
//                 });
//             }
//         };
//     }, [stream]);

//     const handle = useFullScreenHandle();

//     return (
//         <div className='col-span-2' >
//             <div className='py-2' style={{ display: renderLanding() }}>
//                 {receivingCall ? incomingCall : ""}
//             </div>
//             <div className='grid' >
//                 <div className="p-2 text-center">
//                     <div className='font-bold py-2' >{role.name === 'candidate' ? 'Candidate' : 'Assessor'}</div>
//                     <video className="w-full" playsInline muted ref={userVideo} autoPlay />
//                     <div style={{ display: renderCall() }}>

//                         {callAccepted && isConnected ?
//                             <div className='my-2 flex justify-center gird gap-8' >
//                                 <button onClick={() => shareScreen()} title="Share Screen" className='bg-[#3c4043] rounded-full p-[15px]' >
//                                     <span className="text-2xl"  >
//                                         <MdScreenShare color='white' />
//                                     </span>
//                                 </button>
//                             </div>
//                             : ""
//                         }
//                     </div>
//                 </div>
//                 <div className="p-2 text-center" >
//                     <div className='font-bold py-2'>{role.name === 'candidate' ? 'Assessor' : 'Candidate'}</div>
//                     <div style={{ display: renderCall() }}>
//                         <FullScreen handle={handle}>{callAccepted && isConnected ? <video className='w-full h-full' playsInline ref={partnerVideo} autoPlay /> : ""}
//                         </FullScreen>
//                         {
//                             callAccepted && isConnected ?
//                                 <button onClick={handle.enter} title="Full Screen" className='bg-[#3c4043] rounded-full p-[15px] my-2'>
//                                     <span className="text-2xl" >
//                                         <AiOutlineFullscreen color='white' />
//                                     </span>
//                                 </button>
//                                 : ""
//                         }
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// }

// export default VivaVideoCall;