import * as React from 'react';
import http from "../../utils/http";
import { Link } from 'react-router-dom';
import moment from 'moment';

const AssessmentPending = () => {
    const [items, setItems] = React.useState([]);

    const getAssessments = React.useCallback(() => {
        http.get('/api/v1/assessor-assessment/pending').then((res) => setItems(res.data));
    }, []);

    React.useEffect(() => {
        getAssessments()
    }, [getAssessments]);

    return <div>
        <div className="breadcrum">
            <div className="p-4 margin-b-20 bg-white rounded-lg border border-gray-200 shadow-md">
                <h2 className="assess-f">Pending Assessment</h2>
                <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dash-f">
                                <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                Dashboard
                            </Link>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dash-f">Pending Assessment</span>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr className="strat-red">
                        <th scope="col" className="px-6 py-3">
                            ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            BATCH
                        </th>
                        <th scope="col" className="px-6 py-3">
                            ASSESSMENT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            START TIME
                        </th>
                        <th scope="col" className="px-6 py-3">
                            END TIME
                        </th>
                        <th scope="col" className="px-6 py-3">
                            DURATION
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span>Action</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items.length > 0 && items.map((ex, ei) => <tr key={ei} className="bg-white border-b">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{ei + 1}</th>
                        <td className="px-6 py-4">{ex?.batch?.batch_id}</td>
                        <td className="px-6 py-4">{ex?.name}</td>
                        <td className="px-6 py-4">{moment(ex.start_date).format('YYYY-MMM-DD hh:mm A')}</td>
                        <td className="px-6 py-4">{moment(ex.end_date).format('YYYY-MMM-DD hh:mm A')}</td>
                        <td className="px-6 py-4">{ex?.strategy?.duration ? ex?.strategy?.duration + ' Min' : '-'}</td>
                        <td className="px-6 py-4 text-right">
                            -
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
};

export default AssessmentPending;