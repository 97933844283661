import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import http from "../utils/http";

const Exam = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.auth.user);
    const [questions, setQuestion] = React.useState([]);
    const [second_lang, setSecondLang] = React.useState({ slug: 'hin' });
    const [active, setActive] = React.useState(0);
    const [qsSts, setQsSts] = React.useState(false);
    const [finalSts, setFinalSts] = React.useState(false);
    const [feedbackStatus, setFeedbackStatus] = React.useState(false);
    const [feedbackEnable, setFeedbackEnable] = React.useState(false);

    const getExams = React.useCallback(() => {
        http.get(`/api/v1/feedback-question/${id}`).then((res) => {
            setQuestion(res.data.questions.map((v, i) => {
                return { ...v, visited: active === i ? true : false };
            }));
            if (res.data.languages.length > 0) {
                setSecondLang(res.data.languages.find(v => v.slug !== 'eng') || {});
            }
            if (res.data.assessment?.feedback_enable) {
                setFeedbackEnable(true)
            }
            if (res.data.feedbackStatus) {
                setFeedbackStatus(true)
            }
        }).catch(error => {
            navigate('/');
        });
    }, [id, navigate]);

    React.useEffect(() => {
        getExams()
    }, [getExams]);

    if (questions.length === 0) {
        return <div className="flex h-screen w-screen items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    }

    const getLangQuestion = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q?.content;
    };

    const getLangOptionsData = (index, lang = 'eng') => {
        if (questions.length === 0) return [];
        return questions[index].question.question.find(v => v.lang === lang)?.options;
    };

    const counterClass = (index) => {
        if (questions.length === 0) return 'bg-black';
        if (index === active) return 'bg-blue';
        if (questions[index].answer) return 'bg-green';
        if (questions[index].review && questions[index].answer === null && index !== active) return 'bg-yellow !important';
        if (questions[index].visited && questions[index].answer === null && !questions[index].review) return 'bg-red !important';
        return 'bg-black';
    };

    const changeAnswer = (value, index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].answer = value;
        setQuestion(q);
        submitAnswer(index);
    };

    const counterClck = (index, closeModel = false) => {
        if (closeModel) {
            setQsSts(false)
        }
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        setQuestion(q);
        setActive(index);
    };

    const saveNext = (index) => {
        if (questions.length === 0 || questions.length - 1 === index) return false;
        counterClck(index + 1);
    };

    const savePrev = (index) => {
        if (questions.length === 0 || index === 0) return false;
        counterClck(index - 1);
    };

    const reviewLetter = (index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].review = true;
        counterClck(index + 1);
        setQuestion(q);
    };

    const reAttempt = () => {
        setQsSts(false)
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        setQuestion(q);

        // Find the index of the first question with answer === null
        const index = q.findIndex(qu => qu.answer === null);

        // If an unanswered question is found, set it as active
        if (index !== -1) {
            setActive(index);
        }
    };

    const submitAnswer = (index, final_submit = false) => {
        let data = {};
        if (final_submit) {
            data.final_submit = true;
            setQsSts(false);
        } else {
            let q = { ...questions[index] };
            data.question = q.question._id;
            data.answer = q.answer;
            data.review = q.review;
        }
        http.post(`/api/v1/feedback-answer-web/${id}`, data).then((res) => {
            if (final_submit) {
                setFinalSts(true);
                setTimeout(() => {
                    logout();
                }, 6000);
            }
        }).catch(error => {
            console.error(error);
        })
    };

    const logout = () => {
        window.location.href = '/';
    };

    return <div>
        {(feedbackStatus && feedbackEnable) ? <div>
            Already Attempted.
        </div> : <div>
            {!finalSts && <div className='grid lg:grid-cols-4 grid-cols-5 gap-4'>
                <div className='col-span-3'>
                    <div className='question'>
                        <ol className='orderRules' type='1' start={active + 1}>
                            <li>
                                <div>
                                    <div className='eng inline-block' dangerouslySetInnerHTML={{ __html: getLangQuestion(active) }}></div>
                                </div>
                                <div>
                                    {Object.keys(second_lang).length > 0 && getLangQuestion(active, second_lang.slug) && <>
                                        <div className={`${second_lang.slug} inline-block`} dangerouslySetInnerHTML={{ __html: getLangQuestion(active, second_lang.slug) }}></div>
                                    </>}
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className='options input-m'>
                        {getLangOptionsData(active).length > 0 && getLangOptionsData(active).map((opt, oi) => <div key={oi} className="flex items-start mb-6">
                            <div className="flex items-center h-5 lg:option-m">
                                <input
                                    id={`terms-${opt._id}`} // Add unique ID for each input
                                    type="radio"
                                    name={`name-${active + 1}`}
                                    value={opt._id}
                                    onChange={(e) => changeAnswer(e.target.value, active)}
                                    checked={!!(questions.length > 0 && questions[active].answer === opt._id)}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                                />
                            </div>
                            <label
                                htmlFor={`terms-${opt._id}`} // Add corresponding ID of the input
                                className="ml-2 text-sm font-medium text-gray-900 flex flex-col"
                            >
                                <div>
                                    <span className="eng-opt inline-block" dangerouslySetInnerHTML={{ __html: opt.content }}></span>
                                </div>
                                <div>
                                    {Object.keys(second_lang).length > 0 && getLangOptionsData(active, second_lang.slug)[oi].content && (
                                        <>
                                            <span className={`${second_lang.slug}-opt inline-block`} dangerouslySetInnerHTML={{ __html: getLangOptionsData(active, second_lang.slug)[oi].content }}></span>
                                        </>
                                    )}
                                </div>
                            </label>
                        </div>)}
                    </div>
                </div>
                <div className='lg:col-span-1 col-span-2'>
                    <div className="assess-de mb-[70px]">
                        <p>Feedback Questions</p>
                        <div className='overflow-y-auto' style={{ maxHeight: 'calc(100vh - 410px)' }}>
                            <div className='grid lg:grid-cols-7 grid-cols-4 lg:gap-2 gap-[3px] py-2 px-2'>
                                {questions.length > 0 && questions.map((v, i) => <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i)}>{i + 1}</button>)}
                            </div>
                        </div>
                        <div className="assess-bott contents">
                            <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-yellow"></span>Review Later</div>
                            <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-red"></span>UnAnswered</div>
                            <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-black"></span>Not Visited</div>
                            <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-blue"></span>Active</div>
                            <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-green"></span>Answered</div>
                        </div>
                    </div>
                </div>
                <div className='footerbottom lg:pl-50px col-span-4'>
                    <button type="button" className="text-white w-1/4 lg:w-auto cursor-pointer bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2" disabled={!!(active === 0)} onClick={() => savePrev(active)}>Previous</button>
                    {((questions.length - 1) !== active) && <button type="button" className={`text-white w-[31%] lg:w-auto cursor-pointer bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2`} disabled={!!((questions.length - 1) === active)} onClick={() => saveNext(active)}>Save &amp; Next</button>}
                    {((questions.length - 1) !== active) && <button type="button" className="text-white cursor-pointer w-[31%] lg:w-auto bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2" disabled={(questions.length > 0 && questions[active].answer === '') ? true : ''} onClick={() => reviewLetter(active)}>Review Later</button>}
                    {((questions.length - 1) === active) && <button type="button" className="text-white w-1/4 cursor-pointer lg:w-auto bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat" onClick={() => setQsSts(true)}>Submit</button>}
                </div>
            </div>}
            {finalSts && <div className='h-screen flex items-center justify-center'>
                <div className='w-auto text-center'>
                    <div className='text-2xl text-green-600 font-bold'>Feedback Submit Successfully.</div>
                    <div className='text-lg mt-2'>By {user?.name} ({user?.batch?.batch_id})</div>
                    <div className='text-lg mt-2'>Now you can click below to logout or autoredirect after 6 sec.</div>
                    <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'>Go To Home</button>
                </div>
            </div>}
            {qsSts ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" style={{ zIndex: 101 }} >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-medium">
                                        Exam Status
                                    </h3>
                                </div>
                                {/*body*/}
                                <div className="relative p-4 flex-auto overflow-y-auto" style={{ width: 250 }}>
                                    <div className="grid grid-cols-5 gap-2">
                                        <div className="col-span-3">Total Question</div>
                                        <div className="col-span-1">:</div>
                                        <div className="col-span-1">{questions.length}</div>

                                        <div className="col-span-3">Attempted</div>
                                        <div className="col-span-1">:</div>
                                        <div className="col-span-1">{questions.filter(v => v.answer).length}</div>

                                        <div className="col-span-3">Reviews</div>
                                        <div className="col-span-1">:</div>
                                        <div className="col-span-1">{questions.filter(v => !v.answer && v.review).length}</div>

                                        <div className="col-span-3">Not Attempted</div>
                                        <div className="col-span-1">:</div>
                                        <div className="col-span-1">{questions.filter(v => !v.answer && !v.review).length}</div>
                                    </div>
                                </div>
                                {questions.filter(v => !v.answer).length > 0 &&
                                    <div>
                                        <div className="p-4 border-t border-solid border-slate-200 rounded-t">
                                            <div className="font-medium">
                                                Unanswered Questions :
                                            </div>
                                            <div className='grid lg:grid-cols-8 grid-cols-4 lg:gap-2 gap-[3px] py-2 px-2'>
                                                {questions.map((qe, i) => {
                                                    return qe.answer === null && <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i, true)}>{i + 1}</button>
                                                })}
                                            </div>
                                        </div>
                                        <div className="p-4 border-t border-solid border-slate-200 rounded-t">
                                            Kindly attempt all unanswered questions and submit again
                                        </div>
                                    </div>
                                }


                                {/*footer*/}
                                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-medium px-5 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => reAttempt()}
                                    >
                                        Go Back
                                    </button>
                                    {!(questions.filter(v => !v.answer).length > 0) &&
                                        <button
                                            className="bg-blue-700 text-white active:bg-blue-600 font-medium text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => submitAnswer(active, true)}
                                        >
                                            Submit
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" style={{ zIndex: 100 }}></div>
                </>
            ) : null}
        </div>}
    </div>;
};

export default Exam;