import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import http from "../utils/http";
import { db } from "../utils/firebase";
import { onValue, ref } from "firebase/database";
import VivaVideoCall from '../components/Agora/VivaVideoCall';
import {
    createClient,
} from "agora-rtc-react";

let config = {
    mode: "rtc", codec: "vp8", appId: process.env.REACT_APP_AGORA_APP_ID, token: ''
};

const useClient = createClient(config);

const Practical = () => {
    const client = useClient();
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const [assessment, setAssessment] = React.useState('');
    const [questions, setQuestions] = React.useState([]);
    const [active, setActive] = React.useState(0);
    const [second_lang, setSecondLang] = React.useState({});
    const [finalSts, setFinalSts] = React.useState(false);
    const [count, setCount] = React.useState(6);
    const [audioUrl, setAudioUrl] = React.useState();

    const getPracticalList = React.useCallback((assessment) => {
        http.get(`/api/v1/candidate-viva-question/${assessment}`).then((res) => {
            setQuestions(res.data.questions);
            setSecondLang(res.data.languages.find(v => v.slug !== 'eng') || {});
        });
    }, []);

    const getToken = React.useCallback((channelName) => {
        http.post(`/api/v1/agora/rtc/${channelName}/audience/uid/0`).then((res) => {
            config['token'] = res.data.rtcToken;
        });
    }, []);

    const logout = React.useCallback(() => {
        navigate(`/`, { replace: true });
    }, [navigate]);

    React.useEffect(() => {
        const query = ref(db, "viva/" + user._id);
        onValue(query, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                if (questions.length === 0) {
                    setAssessment(data.assessment);
                    getPracticalList(data.assessment);
                }
                setActive(data.question);
                setFinalSts(data.final);
                if (data.final) {
                    setTimeout(() => {
                        logout();
                    }, 6000);
                }
            }
        });
    }, [user, questions, getPracticalList, logout]);

    React.useEffect(() => {
        if (finalSts) {
            const timer = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);

            // Clean up the timer
            return () => clearInterval(timer);
        }
    }, [finalSts, logout])

    React.useEffect(() => {
        if (questions.length > 0) {
            getToken(user._id);
        }
    }, [user, questions, getToken]);

    if (assessment === '' && questions.length === 0) {
        return <div className="flex h-screen w-screen items-center justify-center">
            <span><b>Loading...</b></span>
        </div>;
    }

    const getLangQuestion = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q?.content;
    };

    const textToVoice = (text, lang = 'eng') => {
        http.post(`/api/v1/ai/text-speech`, {
            text: text,
            lang: lang
        }).then((res) => {
            setAudioUrl(res.data.google.audio_resource_url)
        }).catch((err) => {
            console.log(err)
        })
    };

    return <div className='h-screen w-screen'>
        {!finalSts && (questions.length > 0 ? <div className='h-full grid grid-cols-8 gap-2'>
            <div className='col-span-6'>
                <div className='w-full h-full grid grid-cols-7 gap-2'>
                    <div className='col-span-5'>
                        <div>
                            <ol type='1' start={active + 1} className="orderRules">
                                <li>
                                    <div>
                                        <div className='eng inline-block' dangerouslySetInnerHTML={{ __html: getLangQuestion(active) }}></div>
                                        <img src="/mic.jpg" alt="speech" className='inline-block' onClick={() => textToVoice(getLangQuestion(active))} width="20px" height="20px" style={{ cursor: "pointer" }} />
                                    </div>
                                    <div>
                                        {Object.keys(second_lang).length > 0 && getLangQuestion(active, second_lang.slug) && <>
                                            <div className={`${second_lang.slug} inline-block`} dangerouslySetInnerHTML={{ __html: getLangQuestion(active, second_lang.slug) }}></div>
                                            <img src="/mic.jpg" alt="speech" className='inline-block' onClick={() => textToVoice(getLangQuestion(active, second_lang.slug), second_lang.slug)} width="20px" height="20px" style={{ cursor: "pointer" }} />
                                        </>}
                                    </div>
                                    <audio src={audioUrl} autoPlay controls style={{ display: "none" }}></audio>
                                </li>
                            </ol>
                        </div>
                    </div>
                    {/* For Agora */}
                    {/* <VivaVideoCall role={user.role} userId={user._id} channelName={user._id} client={client} config={config} /> */}
                    {/* For Webrtc */}
                    <VivaVideoCall role={user.role} userId={user._id} channelName={user._id} />
                </div>
            </div>
            <div className='col-span-2'>
                <div className="assess-de">
                    <p>Practical Detail</p>
                    <ol className="assess-list">
                        <span className="assess-span"><span className="span-float"><b>Candidate Name:</b></span><li>{user?.name}</li></span>
                        <span className="assess-span"><span className="span-float"><b>SSC:</b></span><li>{user?.sector?.name}</li></span>
                        <span className="assess-span"><span className="span-float"><b>QP:</b></span><li>{user?.jobrole?.name}</li></span>
                    </ol>
                </div>
            </div>
        </div> : "Questions Not Available")}
        {finalSts && <div className='h-screen flex items-center justify-center'>
            <div className='w-auto text-center'>
                <div className='text-2xl text-green-600 font-bold'>Viva Submit Successfully.</div>
                <div className='text-lg mt-2'>Now you can click below to button or auto redirect after {count} sec.</div>
                <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center'>Go To Home</button>
            </div>
        </div>}
    </div>
};

export default Practical;
