import { useState, useEffect, useCallback } from "react";
import http from "../utils/http";

let mediaStream = null;
let recorder = null;

function ScreenRecorder(apiUrl) {
    const [chunks, setChunks] = useState([]);

    const handleStartRecording = async () => {
        const options = { mimeType: "video/webm; codecs=vp9" };

        try {
            // Get screen media
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    mediaSource: "screen",
                    cursor: "always",
                    displaySurface: "monitor",
                    frameRate: { ideal: 60, max: 120 },
                },
                audio: true, // This captures system audio if supported
            });

            // Get user audio (microphone)
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // Combine screen and audio streams
            mediaStream = new MediaStream([
                ...screenStream.getTracks(),
                ...audioStream.getTracks(),
            ]);

            // Initialize recorder
            recorder = new MediaRecorder(mediaStream, options);

            let interval;
            recorder.onstart = () => {
                interval = setInterval(() => {
                    recorder.requestData();
                }, 5000);
            };

            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };

            recorder.onstop = () => {
                clearInterval(interval);
            };

            recorder.start();
        } catch (error) {
            console.error("Error accessing media devices:", error);
        }
    };

    const saveVideo = useCallback(
        (url, blob) => {
            const formData = new FormData();
            formData.append("data", blob, "video.webm");
            formData.append("url", url);
            http.post(apiUrl, formData)
                .then((response) => console.log(response.data))
                .catch((error) => console.error(error));
        },
        [apiUrl]
    );

    useEffect(() => {
        if (chunks.length > 0) {
            const blob = new Blob(chunks, { type: "video/webm" });
            const url = URL.createObjectURL(blob);
            saveVideo(url, blob);
            setChunks([]);
        }
    }, [chunks, saveVideo]);

    const handleStopRecording = () => {
        if (recorder && recorder.state === "recording") {
            recorder.stop();
        }
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
        }
        recorder = null;
    };

    return { handleStartRecording, handleStopRecording, recorder };
}

export default ScreenRecorder;
